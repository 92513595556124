/**
 * User functions
 */

export default (() => {
  const filterFormSub = document.getElementById('filterFormSub') || false
  const filterFormRet = document.getElementById('filterFormRet') || false
  const filterFormProds = document.getElementById('filterFormProds') || false
  const filterFormEdition =
    document.getElementById('filterFormEdition') || false

  // FUNCTIONS

  // Create absolute url based on relative url
  const createAbsUrl = (url) => {
    let environment = '/'
    let firstSplit = location.pathname.split('/')[1]

    // Allowed environments
    switch (firstSplit) {
      case 'dev':
      case 'tst':
      case 'cartzilla':
        environment += firstSplit + '/'
        break
    }

    // Base = origin + environment
    let base = location.origin + environment

    return new URL(url, base).href
  }

  // Function for setting cookie
  const setCookie = (name, value, days) => {
    // Default parameter
    days = typeof days !== 'undefined' ? days : 365

    // Set expire
    let date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    let expires = '; expires=' + date.toUTCString()

    // Set cookie
    document.cookie = name + '=' + (value || '') + expires + ';path=/;secure'
  }

  // Function for getting cookie
  const getCookie = (cname) => {
    let name = cname + '='
    let ca = document.cookie.split(';')

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') c = c.substring(1)
      if (c.indexOf(name) === 0) return c.substring(name.length, c.length)
    }

    return ''
  }

  // Function for checking if string is valid JSON
  const isJson = (item) => {
    let value = typeof item !== 'string' ? JSON.stringify(item) : item
    try {
      value = JSON.parse(value)
    } catch {
      return false
    }

    return typeof value === 'object' && value !== null
  }

  // Function for up or downvoting a review
  const upDownVote = async (e) => {
    // Determine is vote is up or down
    let upvote = true
    if (e.currentTarget.querySelector('i').classList.contains('ci-thumbs-down'))
      upvote = false

    // Increment vote count and change style
    let voteCount = e.currentTarget.querySelector('.vote-count')
    voteCount.textContent = parseInt(voteCount.textContent) + 1
    voteCount.classList.add('text-dark-emphasis')
    e.currentTarget.classList.remove('animate-scale')

    // Prevent another click
    e.currentTarget.removeEventListener('click', upDownVote)

    // Ajax
    let params = new URLSearchParams({
      opinionId: e.currentTarget.dataset.id,
      upvote: upvote ? 1 : 0,
    })

    try {
      fetch(createAbsUrl('api/postUpDownVote.php'), {
        method: 'POST',
        body: params,
      })
    } catch (e) {
      console.error(`Error: ${e}`)
    }
  }

  // Function for searching term
  const searchTerm = async (term) => {
    let params = new URLSearchParams({ term: term })
    let json = []

    try {
      let response = await fetch(createAbsUrl('api/getAutocomplete.php'), {
        method: 'POST',
        body: params,
      })

      json = await response.json()
    } catch (e) {
      console.error(`Error: ${e}`)
    }

    return json
  }

  // Function for loading slider element in subscription form
  const loadSlider = (name, template) => {
    let slider = document.getElementById(name) || false

    // If element does not exists, because of different format being used, then ignore this code
    if (!slider) return

    let sliderLabel = document.getElementById('label-' + name)
    let values = slider.dataset.values.split(',')
    let valueInput = document.getElementsByName(name)

    let format = {
      to: function (value) {
        return values[Math.round(value)]
      },
      from: function (value) {
        return values.indexOf(value)
      },
    }

    slider.noUiSlider.updateOptions({
      start: valueInput[0].value,
      range: { min: 0, max: values.length - 1 },
      step: 1,
      tooltips: false,
      format: format,
    })

    // Set label
    slider.noUiSlider.on('update', function (values, handle) {
      let toValue = ''

      if (values[handle] == -1) {
        toValue = 'Toon alles'
        if (template == 4) {
          // Enable 4G/5G options
          document.getElementById('bundle3Type_2').disabled = false // Enable 4G option
          document.getElementById('bundle3Type_3').disabled = false // Enable 5G option
        }
      } else if (values[handle] == 0) toValue = 'geen'
      else if (values[handle] >= 99999) toValue = 'onbeperkt'
      else {
        switch (template) {
          case 1: // Data
            toValue = values[handle] / 1000 + ' GB of meer'
            break
          case 2: // Minutes
            toValue = values[handle] + ' of meer'
            break
          case 3: // Prices
            toValue = 'max. € ' + values[handle]
            break
          case 4: // Speed
            if (values[handle] < 1000)
              toValue = 'min. ' + values[handle] + ' Mbit/s'
            else toValue = 'min. ' + values[handle] / 1000 + ' Gbit/s'

            // Disable 4G/5G options
            document.getElementById('bundle3Type_2').checked = false
            document.getElementById('bundle3Type_3').checked = false
            document.getElementById('bundle3Type_2').disabled = true // Disable 4G option
            document.getElementById('bundle3Type_3').disabled = true // Disable 5G option

            break
        }
      }

      sliderLabel.innerHTML = toValue

      // Update input element value (overwrite theme, since we do allow negative values)
      valueInput[0].value = Math.round(values[handle])
    })

    // Submit parent form
    slider.noUiSlider.on('change', () => {
      filterFormSub.requestSubmit()
    })
  }

  // Function for loading minMaxAmount slider element in product form
  const loadAmountSlider = () => {
    let slider = document.getElementById('minMaxAmount') || false

    // If element does not exists, because of different format being used, then ignore this code
    if (!slider) return

    let sliderLabel = document.getElementById('label-amount')
    let valueMin = document.getElementsByName('minAmount')
    let valueMax = document.getElementsByName('maxAmount')

    slider.noUiSlider.updateOptions({
      start: [valueMin[0].value, valueMax[0].value],
      range: { min: 0, max: 2000 },
      step: 1,
      tooltips: false,
    })

    // Set label
    slider.noUiSlider.on('update', function (values) {
      let toValue = ''

      if (values[0] + values[1] == 0) {
        toValue = slider.dataset.showall
      } else {
        toValue =
          slider.dataset.currency +
          ' ' +
          values[0] +
          ' - ' +
          slider.dataset.currency +
          ' ' +
          values[1]
      }

      sliderLabel.innerHTML = toValue

      // Update input element value (overwrite theme, since we do allow negative values)
      valueMin[0].value = Math.round(values[0])
      valueMax[0].value = Math.round(values[1])
    })

    // Submit parent form
    slider.noUiSlider.on('change', () => {
      filterFormProds.requestSubmit()
    })
  }

  // ACTIONS

  // Call filterFormSubscription functions
  if (filterFormSub) {
    loadSlider('minBundle3', 1)
    loadSlider('minBundle1', 2)
    loadSlider('maxPpm', 3)
    loadSlider('maxPay', 3)
    loadSlider('minSpeed', 4)

    // Handle storage options (in filter)
    const storage =
      document.querySelectorAll('#filterFormSub input[name="storage"]') || false

    if (storage) {
      storage.forEach((element) => {
        // Make unclickable
        element.addEventListener('click', (e) => {
          if (e.target.dataset.previous === 'false') {
            e.target.checked = false

            // Create and dispatch new event
            let change = new Event('change')
            e.target.dispatchEvent(change)
          }
        })

        // Submit form
        element.addEventListener('change', (e) => {
          e.target.dataset.previous = !e.target.checked

          // Set previouw on all other color options
          storage.forEach((element2) => {
            if (element2 !== element) element2.dataset.previous = 'true'
          })

          e.target.form.requestSubmit()
        })
      })
    }

    // Handle color options (in filter)
    const color =
      document.querySelectorAll('#filterFormSub input[name="color"]') || false

    if (color) {
      color.forEach((element) => {
        // Make unclickable
        element.addEventListener('click', (e) => {
          if (e.target.dataset.previous === 'false') {
            e.target.checked = false

            // Create and dispatch new event
            let change = new Event('change')
            e.target.dispatchEvent(change)
          }
        })

        // Change rear image when clicked & submit form
        element.addEventListener('change', (e) => {
          e.target.dataset.previous = !e.target.checked

          // Set previouw on all other color options
          color.forEach((element2) => {
            if (element2 !== element) element2.dataset.previous = 'true'
          })

          // Change rear image when clicked
          if (e.target.dataset.image) {
            let hover = document.querySelector('#filterFormSub .hover-back')

            if (hover) {
              hover.src = e.target.dataset.image
              hover.classList.add('opacity-100')
              hover.classList.remove('hover-effect-target')
            }
          }

          e.target.form.requestSubmit()
        })
      })
    }

    // Handle renewal select element
    const renewalSelect = document.getElementById('renewalId') || false

    if (renewalSelect) {
      renewalSelect.addEventListener('change', (e) => {
        let operatorIds = document.getElementsByName('operatorIds[]')

        // Disable operator selection
        if (e.target.value > 0) {
          if (operatorIds.length !== 0) {
            operatorIds.forEach((element) => {
              element.checked = false
              element.disabled = true
            })
          }
        } else {
          // Enable operator selection
          if (operatorIds.length !== 0) {
            operatorIds.forEach((element) => {
              element.disabled = false
            })
          }
        }
      })
    }

    // Handle change submit function subscription price cards
    filterFormSub.onsubmit = async (e) => {
      e.preventDefault()

      let priceCardResults = document.getElementById('priceCardResults')
      priceCardResults.classList.add('opacity-25') // Give some loading animation

      let params = new URLSearchParams([...new FormData(e.target).entries()])
      try {
        let response = await fetch(createAbsUrl('api/getPriceCards.php'), {
          method: 'POST',
          body: params,
        })

        priceCardResults.innerHTML = await response.text() // Replaces body with response
        priceCardResults.classList.remove('opacity-25') // Give some loading animation
      } catch (e) {
        console.error(`Error: ${e}`)
      }

      addModal() // Add modal functionality
    }
  }

  // Call filterFormRetail functions
  if (filterFormRet) {
    // Handle storage options (in filter)
    const storage =
      document.querySelectorAll('#filterFormRet input[name="storage"]') || false

    if (storage) {
      storage.forEach((element) => {
        // Make unclickable
        element.addEventListener('click', (e) => {
          if (e.target.dataset.previous === 'false') {
            e.target.checked = false

            // Create and dispatch new event
            let change = new Event('change')
            e.target.dispatchEvent(change)
          }
        })

        // Submit form
        element.addEventListener('change', (e) => {
          e.target.dataset.previous = !e.target.checked

          // Set previouw on all other color options
          storage.forEach((element2) => {
            if (element2 !== element) element2.dataset.previous = 'true'
          })

          e.target.form.requestSubmit()
        })
      })
    }

    // Handle color options (in filter)
    const color =
      document.querySelectorAll('#filterFormRet input[name="color"]') || false

    if (color) {
      color.forEach((element) => {
        // Make unclickable
        element.addEventListener('click', (e) => {
          if (e.target.dataset.previous === 'false') {
            e.target.checked = false

            // Create and dispatch new event
            let change = new Event('change')
            e.target.dispatchEvent(change)
          }
        })

        // Change rear image when clicked & submit form
        element.addEventListener('change', (e) => {
          e.target.dataset.previous = !e.target.checked

          // Set previouw on all other color options
          color.forEach((element2) => {
            if (element2 !== element) element2.dataset.previous = 'true'
          })

          // Change rear image when clicked
          if (e.target.dataset.image) {
            let hover = document.querySelector('#filterFormRet .hover-back')

            if (hover) {
              hover.src = e.target.dataset.image
              hover.classList.add('opacity-100')
              hover.classList.remove('hover-effect-target')
            }
          }

          e.target.form.requestSubmit()
        })
      })
    }

    // Handle condition options (in filter)
    const conditionIds =
      document.querySelectorAll(
        '#filterFormRet input[name="conditionIds[]"]'
      ) || false

    if (conditionIds) {
      conditionIds.forEach((element) => {
        element.addEventListener('change', (e) => {
          e.target.form.requestSubmit()
        })
      })
    }

    // Handle change submit function
    filterFormRet.onsubmit = async (e) => {
      e.preventDefault()

      let priceListResults = document.getElementById('priceListResults')
      priceListResults.classList.add('opacity-25') // Give some loading animation

      let params = new URLSearchParams([...new FormData(e.target).entries()])
      try {
        let response = await fetch(createAbsUrl('api/getPriceList.php'), {
          method: 'POST',
          body: params,
        })

        priceListResults.innerHTML = await response.text() // Replaces body with response
        priceListResults.classList.remove('opacity-25') // Give some loading animation
      } catch (e) {
        console.error(`Error: ${e}`)
      }
    }
  }

  // Call filterFormEdition functions
  if (filterFormEdition) {
    // Handle storage options (in filter)
    const storage =
      document.querySelectorAll('#filterFormEdition input[name="storage"]') ||
      false

    if (storage) {
      storage.forEach((element) => {
        // Make unclickable
        element.addEventListener('click', (e) => {
          if (e.target.dataset.previous === 'false') {
            e.target.checked = false

            // Create and dispatch new event
            let change = new Event('change')
            e.target.dispatchEvent(change)
          }
        })

        // Submit form
        element.addEventListener('change', (e) => {
          e.target.dataset.previous = !e.target.checked

          // Set previouw on all other color options
          storage.forEach((element2) => {
            if (element2 !== element) element2.dataset.previous = 'true'
          })

          e.target.form.requestSubmit()
        })
      })
    }

    // Handle color options (in filter)
    const color =
      document.querySelectorAll('#filterFormEdition input[name="color"]') ||
      false

    if (color) {
      color.forEach((element) => {
        // Make unclickable
        element.addEventListener('click', (e) => {
          if (e.target.dataset.previous === 'false') {
            e.target.checked = false

            // Create and dispatch new event
            let change = new Event('change')
            e.target.dispatchEvent(change)
          }
        })

        // Change rear image when clicked & submit form
        element.addEventListener('change', (e) => {
          e.target.dataset.previous = !e.target.checked

          // Set previouw on all other color options
          color.forEach((element2) => {
            if (element2 !== element) element2.dataset.previous = 'true'
          })

          e.target.form.requestSubmit()
        })
      })
    }

    // Handle change submit function subscription price cards
    filterFormEdition.onsubmit = async (e) => {
      e.preventDefault()

      let json = []
      let subscrPrice = document.getElementById('subscr-price') || false
      let retailPrice = document.getElementById('retail-price') || false
      let refurbPrice = document.getElementById('refurb-price') || false

      // Give some loading animation
      if (subscrPrice) subscrPrice.classList.add('opacity-25')
      if (retailPrice) retailPrice.classList.add('opacity-25')
      if (refurbPrice) refurbPrice.classList.add('opacity-25')

      // Store form data
      let params = new URLSearchParams([...new FormData(e.target).entries()])

      try {
        let response = await fetch(createAbsUrl('api/getLowestPrices.php'), {
          method: 'POST',
          body: params,
        })

        json = await response.json()
      } catch (e) {
        console.error(`Error: ${e}`)
      }

      // Change product image slider when color is selected
      let productImgSwiper =
        document.querySelector('#productImages.swiper') || false

      if (productImgSwiper && params.has('color')) {
        let label = document.querySelector(
          'label[for="color-' + params.get('color').toLowerCase() + '"]'
        )

        // Check if required dataset exists
        if ('index' in label.dataset) {
          // Get reference of product image swiper
          let productImgSwiperRef = productImgSwiper.swiper

          // Slide to index
          productImgSwiperRef.slideTo(
            parseInt(label.dataset.index) + 1,
            400,
            false
          )
        }
      }

      // Update buttons
      let buttons = document.querySelectorAll('#cta-buttons a.btn') || false

      if (buttons) {
        buttons.forEach((element) => {
          // Skip bookmark links
          if (element.href.indexOf('#') !== -1) return

          let addr = new URL(element.href)

          if (params.has('color'))
            addr.searchParams.set('color', params.get('color'))
          if (params.has('storage'))
            addr.searchParams.set('storage', params.get('storage'))

          element.href = addr.toString()
        })
      }

      // Update subscription price
      if (subscrPrice) {
        subscrPrice.innerHTML = json.subscrPrice
        subscrPrice.classList.remove('opacity-25')
      }

      // Update retail price
      if (retailPrice) {
        retailPrice.innerHTML = json.retailPrice
        retailPrice.classList.remove('opacity-25')
      }

      // Update refurb price
      if (refurbPrice) {
        refurbPrice.innerHTML = json.refurbPrice
        refurbPrice.classList.remove('opacity-25')
      }

      // Disable unavailable combinations
      Object.entries(json.combinations).forEach(([storage, colors]) => {
        // Disable colors when storage is selected
        if (params.get('storage') == storage) {
          // Loop through each color
          color.forEach((element) => {
            let label = document.querySelector(
              'label[for="color-' + element.value.toLowerCase() + '"]'
            )

            if (!colors.includes(element.value)) {
              label.classList.add('opacity-25', 'pe-none')
            } else {
              label.classList.remove('opacity-25', 'pe-none')
            }
          })
        } else {
          // Disable storage when not available for selected color
          if (params.has('color')) {
            let radio = document.getElementById('storage-' + storage)

            if (radio) {
              if (!colors.includes(params.get('color'))) radio.disabled = true
              else radio.disabled = false
            }
          }
        }

        // Enable when nothing selected
        if (!params.has('color') && !params.has('storage')) {
          // Enable colors
          color.forEach((element) => {
            let label = document.querySelector(
              'label[for="color-' + element.value.toLowerCase() + '"]'
            )

            label.classList.remove('opacity-25', 'pe-none')
          })

          // Enable storage
          let radio = document.getElementById('storage-' + storage)

          radio.disabled = false
        }
      })

      // Update children forms
      if (params.has('color')) {
        let colors =
          document.querySelectorAll(
            '[id^="color"][id$="-' +
              params.get('color') +
              '"]:not([id^="color-"])'
          ) || false

        if (colors) {
          colors.forEach((element) => {
            if (!element.checked) element.click()
          })
        }
      } else {
        // Disable all checked
        let colors =
          document.querySelectorAll(
            '[id^="colorSub-"]:checked,[id^="colorRet-"]:checked'
          ) || false

        if (colors) {
          colors.forEach((element) => {
            element.click()
          })
        }
      }

      if (params.has('storage')) {
        let storages =
          document.querySelectorAll(
            '[id^="storage"][id$="-' +
              params.get('storage') +
              '"]:not([id^="storage-"])'
          ) || false

        if (storages) {
          storages.forEach((element) => {
            if (!element.checked) element.click()
          })
        }
      } else {
        // Disable all checked
        let storages =
          document.querySelectorAll(
            '[id^="storageSub-"]:checked,[id^="storageRet-"]:checked'
          ) || false

        if (storages) {
          storages.forEach((element) => {
            element.click()
          })
        }
      }
    }
  }

  // Call filterFormProds functions
  if (filterFormProds) {
    loadAmountSlider()

    // Handle change submit function
    filterFormProds.onsubmit = async (e) => {
      e.preventDefault()

      let json = []
      let prodGrid = document.getElementById('prodGrid')
      prodGrid.classList.add('opacity-25') // Give some loading animation

      // Disable pagination buttons
      let pageItems =
        document.querySelectorAll('#prodPagination li.page-item') || false

      if (pageItems) {
        pageItems.forEach((element) => {
          element.classList.add('disabled')
        })
      }

      // Store form data
      let params = new URLSearchParams([...new FormData(e.target).entries()])

      // Manual add sortBy select because that does not reside in form
      let sortBy = document.getElementById('sortBy')
      params.append('sortBy', sortBy.options[sortBy.selectedIndex].value)

      try {
        let response = await fetch(createAbsUrl('api/getProds.php'), {
          method: 'POST',
          body: params,
        })

        json = await response.json()
      } catch (e) {
        console.error(`Error: ${e}`)
      }

      prodGrid.innerHTML = json.products // Replaces code with response
      prodGrid.classList.remove('opacity-25') // Give some loading animation
      document.getElementById('prodFilters').innerHTML = json.filters
      document.getElementById('prodPagination').innerHTML = json.pagination
      document.querySelectorAll('.prodResults').forEach(function (element) {
        element.innerHTML = json.results
      })

      // Setup like and pagination functionality
      addLike()
      addPagination()
    }

    // Feature
    const features = document.querySelectorAll('[id^="feature-"]') || false

    if (features) {
      features.forEach((feature) => {
        feature.addEventListener('change', (e) => {
          if (!feature.checked) {
            // Deselect details
            let details =
              document.querySelectorAll(
                '[id^="detail-' + feature.value + '-"]'
              ) || false

            details.forEach((detail) => {
              if (detail.tagName.toLowerCase() == 'select') {
                detail.selectedIndex = -1
              } else if (detail.tagName.toLowerCase() == 'input') {
                detail.checked = false
              }
            })

            // Slide plusses to the left
            let plusses = document.querySelectorAll(
              'div.plus-slider[data-feature="' + feature.value + '"]'
            )

            plusses.forEach((plus) => {
              plus.noUiSlider.set(0)
            })
          }

          // Submit form
          e.target.form.requestSubmit()
        })
      })
    }

    // Details
    const details = document.querySelectorAll('[id^="detail-"]') || false

    if (details) {
      details.forEach((element) => {
        element.addEventListener('change', (e) => {
          e.target.form.requestSubmit()
        })
      })
    }

    // Add nouislider to plus sliders
    const plusSlider = document.querySelectorAll('div.plus-slider') || false

    if (plusSlider) {
      plusSlider.forEach((slider) => {
        let sliderSelect = slider.querySelector('select')
        let sliderLabel = document.getElementById('label-' + sliderSelect.id)
        let options = sliderSelect.options

        slider.noUiSlider.updateOptions({
          start: options.selectedIndex,
          range: { min: 0, max: options.length - 1 },
          step: 1,
          tooltips: false,
        })

        // Update label
        slider.noUiSlider.on('update', function (values, handle) {
          sliderLabel.innerHTML = options[Math.round(values[handle])].text

          // Update input element value (overwrite theme, since we do allow negative values)
          sliderSelect.value = options[Math.round(values[handle])].value

          // Enable parent feature checkbox
          if (Math.round(values[handle]) > 0) {
            let parentFeature = document.getElementById(
              'feature-' + sliderSelect.dataset.feature
            )

            if (!parentFeature.checked) {
              parentFeature.checked = true

              // Create and dispatch new event
              let click = new Event('click')
              parentFeature.dispatchEvent(click)
            }
          }
        })

        // Submit parent form
        slider.noUiSlider.on('change', () => {
          document.getElementById('filterFormProds').requestSubmit()
        })
      })
    }
  }

  // Add functionality to Difference Toggle(s) in comparison table
  const differenceToggles =
    document.querySelectorAll('[id^="differenceToggle"]') || false

  if (differenceToggles) {
    differenceToggles.forEach((element) => {
      element.addEventListener('change', (e) => {
        if (e.currentTarget.checked) {
          // Make rows with matching specs opaque
          let same = document.querySelectorAll(
            '.table-comparison[data-hash="' +
              element.dataset.hash +
              '"] tr.fs-sm:not(.spec-different), .table-comparison[data-hash="' +
              element.dataset.hash +
              '"] tr.spec-group'
          )

          if (same.length !== 0) {
            same.forEach((element) => {
              element.classList.add('opacity-25')
            })
          }

          // Highlight rows with different specs
          let different = document.querySelectorAll(
            '.table-comparison[data-hash="' +
              element.dataset.hash +
              '"] tr.spec-different > td'
          )

          if (different.length !== 0) {
            different.forEach((element) => {
              element.classList.add('bg-info-subtle')

              if (!element.classList.contains('position-relative'))
                element.classList.add('text-muted')

              // Make group name visible
              let el = element.parentNode || false

              // Move up DOM to find first group name
              while (el) {
                if (el.classList.contains('spec-group')) {
                  el.classList.remove('opacity-25')
                  break
                }

                el = el.previousElementSibling || false
              }
            })
          }

          // Highlight different specs in row
          let highlight = document.querySelectorAll(
            '.table-comparison[data-hash="' +
              element.dataset.hash +
              '"] tr.spec-different span.spec-different'
          )

          if (highlight.length !== 0) {
            highlight.forEach((element) => {
              element.classList.add('fw-bold', 'text-body-emphasis')
            })
          }
        } else {
          let same = document.querySelectorAll(
            '.table-comparison[data-hash="' +
              element.dataset.hash +
              '"] tr.fs-sm.opacity-25:not(.spec-different), .table-comparison[data-hash="' +
              element.dataset.hash +
              '"] tr.spec-group.opacity-25'
          )

          if (same.length !== 0) {
            same.forEach((element) => {
              element.classList.remove('opacity-25')
            })
          }

          let different = document.querySelectorAll(
            '.table-comparison[data-hash="' +
              element.dataset.hash +
              '"] tr.spec-different > td'
          )

          if (different.length !== 0) {
            different.forEach((element) => {
              element.classList.remove('bg-info-subtle')

              if (!element.classList.contains('position-relative'))
                element.classList.remove('text-muted')
            })
          }

          let highlight = document.querySelectorAll(
            '.table-comparison[data-hash="' +
              element.dataset.hash +
              '"] tr.spec-different span.spec-different'
          )

          if (highlight.length !== 0) {
            highlight.forEach((element) => {
              element.classList.remove('fw-bold', 'text-body-emphasis')
            })
          }
        }
      })
    })
  }

  // Function for adding modal link functionality to certain buttons
  const addModal = () => {
    let modalButtons = document.querySelectorAll('[data-modalcat]') || false

    if (modalButtons) {
      modalButtons.forEach((element) => {
        element.addEventListener('click', async () => {
          //e.preventDefault()

          let json = []
          let params = new URLSearchParams({
            cat: element.dataset.modalcat,
            id: element.dataset.modalid,
          })

          try {
            let response = await fetch(createAbsUrl('api/getInfoModal.php'), {
              method: 'POST',
              body: params,
            })

            json = await response.json()

            let modal = document.querySelector('#infoModal')

            modal.querySelector('.modal-title').innerHTML = json.title
            modal.querySelector('.modal-body').innerHTML = json.body
            modal.querySelector('.modal-footer').innerHTML = json.footer

            // Add extra styling?
            if (typeof json.className !== 'undefined') {
              modal.querySelector('.modal-dialog').classList.add(json.className)
            }
          } catch (e) {
            console.error(`Error: ${e}`)
          }
        })
      })
    }
  }

  // Function for adding pagination functionality to certain buttons
  const addPagination = () => {
    let prodPages =
      document.querySelectorAll('#prodPagination [data-page]') || false

    if (prodPages) {
      prodPages.forEach((element) => {
        element.addEventListener('click', (e) => {
          e.preventDefault()

          // Change page number
          document.querySelector('#filterFormProds input[name="page"]').value =
            element.dataset.page

          // Submit form
          document.getElementById('filterFormProds').requestSubmit()
        })
      })
    }
  }

  // Function for adding like functionality to certain buttons
  const addLike = () => {
    let likeButtons = document.querySelectorAll('.btn-like') || false

    if (likeButtons) {
      likeButtons.forEach((element) => {
        element.addEventListener('click', () => {
          let likeCount = document.getElementById('likeCount')
          let like = element.querySelector('.ci-heart') || false

          // Liking
          if (like) {
            // Change styling for both hearts
            let hearts = document.querySelectorAll(
              '.btn-like[data-id="' + element.dataset.id + '"] i.ci-heart'
            )

            hearts.forEach((heart) => {
              heart.classList.remove('ci-heart')
              heart.classList.add('ci-heart-filled', 'text-danger')
            })

            // Show or hide container
            const likeContainer =
              like.closest('div.hover-effect-target.opacity-0') || false

            if (likeContainer)
              likeContainer.classList.remove('hover-effect-target', 'opacity-0')

            // Update like count bubble (Add 1)
            likeCount.textContent = parseInt(likeCount.textContent) + 1
            if (likeCount.classList.contains('opacity-0'))
              likeCount.classList.remove('opacity-0')

            // Store product id in cookie
            let likings = getCookie('like')

            // Check is valid JSON, otherwise create
            if (!isJson(likings)) likings = {}
            else likings = JSON.parse(likings)

            // Create prodcat key when not exists
            if (!(element.dataset.prodcat in likings))
              likings[element.dataset.prodcat] = []

            // Add product to array
            likings[element.dataset.prodcat].push(element.dataset.id)

            // Store object in cookie as JSON
            setCookie('like', JSON.stringify(likings))
          } else {
            // Unliking
            const unlike = element.querySelector('.ci-heart-filled') || false

            if (unlike) {
              // Change styling for both hearts
              let hearts = document.querySelectorAll(
                '.btn-like[data-id="' +
                  element.dataset.id +
                  '"] i.ci-heart-filled'
              )

              hearts.forEach((heart) => {
                heart.classList.remove('ci-heart-filled', 'text-danger')
                heart.classList.add('ci-heart')
              })

              // Show or hide container
              const likeContainer =
                unlike.closest(
                  'div.position-absolute.top-0.end-0:not(.d-lg-none)'
                ) || false
              if (likeContainer)
                likeContainer.classList.add('hover-effect-target', 'opacity-0')

              // Update like count bubble (minus 1)
              likeCount.textContent = parseInt(likeCount.textContent) - 1
              if (parseInt(likeCount.textContent) === 0)
                likeCount.classList.add('opacity-0')

              // Remove product id from cookie
              let likings = getCookie('like')

              // Check is valid JSON, otherwise create
              if (!isJson(likings)) likings = {}
              else likings = JSON.parse(likings)

              // Create prodcat key when not exists
              if (!(element.dataset.prodcat in likings))
                likings[element.dataset.prodcat] = []

              // Remove product from array
              let index = likings[element.dataset.prodcat].indexOf(
                element.dataset.id
              )

              if (index > -1) {
                likings[element.dataset.prodcat].splice(index, 1)
              }

              // Store object in cookie as JSON
              setCookie('like', JSON.stringify(likings))
            }
          }
        })
      })
    }
  }

  // Add functionality to autocomplete search
  const searchSelect = document.getElementById('q') || false

  if (searchSelect) {
    // Declare choices js object

    /* eslint-disable no-unused-vars, no-undef */
    let searchChoices = new Choices('#q', {
      allowHTML: true,
      removeItemButton: false,
      editItems: false,
      searchEnabled: true,
      shouldSort: false,
      itemSelectText: '',
      placeholder: false,
      searchPlaceholderValue: searchSelect.dataset.placeholder,
      loadingText: searchSelect.dataset.placeholder,
      noResultsText: searchSelect.dataset.noresults,
      noChoicesText: searchSelect.dataset.nochoices,
      maxItemCount: 10,
      singleModeForMultiSelect: true,
      classNames: {
        containerInner: [
          'form-select',
          'rounded-pill',
          'bg-light',
          'border-light',
          'text-body',
          'text-reset',
        ],
      },
    })
    /* eslint-enable no-unused-vars, no-undef */

    let searchTimeout = null

    searchSelect.addEventListener(
      'search',
      (e) => {
        // Stop potential previous search
        clearTimeout(searchTimeout)

        // Start timer
        searchTimeout = setTimeout(async () => {
          // Load remote results
          let choices = await searchTerm(e.detail.value)

          // Populate choices
          searchChoices.setChoices(choices, 'value', 'label', true)
        }, 350)
      },
      false
    )
  }

  // Add functionality to brand and product select dropdown
  const prodSelect = document.getElementById('prod-select') || false

  if (prodSelect) {
    /* eslint-disable no-unused-vars, no-undef */
    const prodChoices = new Choices('#prod-select', {
      allowHTML: true,
      searchPlaceholderValue: 'Search...',
      removeItemButton: true,
      editItems: true,
      searchEnabled: true,
      shouldSort: false,
      itemSelectText: '',
      classNames: {
        containerInner: 'form-control',
      },
    })
    /* eslint-enable no-unused-vars, no-undef */

    document
      .getElementById('brand-select')
      .addEventListener('change', async (e) => {
        try {
          prodChoices.setChoices(
            () => {
              return fetch(
                createAbsUrl(
                  'api/getBrandProds.php?id=' +
                    e.currentTarget.value +
                    '&prodcat=' +
                    e.currentTarget.dataset.prodcat
                )
              )
                .then(function (response) {
                  return response.json()
                })
                .then(function (data) {
                  return data.choices.map(function (choice) {
                    return { value: choice.value, label: choice.label }
                  })
                })
            },
            'value',
            'label',
            true
          )
        } catch (e) {
          console.error(`Error: ${e}`)
        }
      })
  }

  // Reload contents of like offcanvas when opened
  const likeOffcanvas = document.getElementById('favorites') || false

  if (likeOffcanvas) {
    likeOffcanvas.addEventListener('show.bs.offcanvas', async () => {
      try {
        let response = await fetch(createAbsUrl('api/getLikes.php'), {
          method: 'GET',
        })

        // Replaces body with response
        document.querySelector('#favorites .offcanvas-body').innerHTML =
          await response.text()
      } catch (e) {
        console.error(`Error: ${e}`)
      }
    })
  }

  // Submit reminder form
  const reminderForms = document.getElementsByName('reminderForm') || false

  if (reminderForms) {
    reminderForms.forEach((element) => {
      element.addEventListener(
        'submit',
        async (e) => {
          if (element.checkValidity()) {
            e.preventDefault()
            e.stopPropagation()

            element.querySelector('button[type="submit"]').disabled = true // Disable submit button
            element.classList.add('opacity-25') // Give some loading animation

            let params = new URLSearchParams([
              ...new FormData(e.target).entries(),
            ])
            try {
              let response = await fetch(createAbsUrl('api/postReminder.php'), {
                method: 'POST',
                body: params,
              })

              element.innerHTML = await response.text() // Replaces body with response
              element.classList.remove('opacity-25') // Give some loading animation
            } catch (e) {
              console.error(`Error: ${e}`)
            }
          }
        },
        false
      )
    })
  }

  // Submit review form
  const reviewForm = document.getElementById('reviewForm') || false

  if (reviewForm) {
    reviewForm.addEventListener(
      'submit',
      async (e) => {
        if (reviewForm.checkValidity()) {
          e.preventDefault()
          e.stopPropagation()

          document.getElementById('reviewSubmit').disabled = true // Disable submit button
          reviewForm.classList.add('opacity-25') // Give some loading animation

          let params = new URLSearchParams([
            ...new FormData(e.target).entries(),
          ])
          try {
            let response = await fetch(createAbsUrl('api/postReview.php'), {
              method: 'POST',
              body: params,
            })

            reviewForm.innerHTML = await response.text() // Replaces body with response
            reviewForm.classList.remove('opacity-25') // Give some loading animation
          } catch (e) {
            console.error(`Error: ${e}`)
          }
        }
      },
      false
    )
  }

  // Submit contact form
  const contactForm = document.getElementById('contactForm') || false

  if (contactForm) {
    contactForm.addEventListener(
      'submit',
      async (e) => {
        if (contactForm.checkValidity()) {
          e.preventDefault()
          e.stopPropagation()

          document.getElementById('contactSubmit').disabled = true // Disable submit button
          contactForm.classList.add('opacity-25') // Give some loading animation

          let params = new URLSearchParams([
            ...new FormData(e.target).entries(),
          ])
          try {
            let response = await fetch(createAbsUrl('api/postContact.php'), {
              method: 'POST',
              body: params,
            })

            contactForm.innerHTML = await response.text() // Replaces body with response
            contactForm.classList.remove('opacity-25') // Give some loading animation
          } catch (e) {
            console.error(`Error: ${e}`)
          }
        }
      },
      false
    )
  }

  // Up & downvote buttons
  const upDownVoteButtons =
    document.querySelectorAll('.btn-updownvote') || false

  if (upDownVoteButtons) {
    upDownVoteButtons.forEach((element) => {
      element.addEventListener('click', upDownVote)
    })
  }

  // RUN ONLOAD

  addLike() // Add like functionality
  addPagination() // Add prod pagination functionality
  addModal() // Add modal link functionality
})()
